.profile {
  display: flex;
  padding: 24px;
  position: relative;
  margin-left: 10px; }
  @media (max-width: 1024px) {
    .profile {
      flex-flow: column;
      align-items: center;
      text-align: center; } }
  .profile h2 {
    margin-bottom: 10px;
    font: bold; }
    @media (max-width: 640px) {
      .profile h2 {
        margin-bottom: 10px !important; } }
  .profile h3 {
    font-size: 20px;
    display: inline;
    color: #044389; }
    @media (max-width: 640px) {
      .profile h3 {
        margin-bottom: 0; } }
  .profile h4 {
    font-size: 20px;
    font-style: normal;
    color: #044389;
    margin-top: 20px;
    margin-bottom: 20px; }
    @media (max-width: 640px) {
      .profile h4 {
        margin-bottom: 0; } }
  .profile p {
    display: inline; }
  .profile .profile-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px; }
    @media (max-width: 640px) {
      .profile .profile-image-wrapper {
        width: 100%;
        order: 1;
        margin-top: 30px; } }
    .profile .profile-image-wrapper .circleBox {
      width: 181px;
      height: 165px;
      background: white;
      border-radius: 100px;
      border: 1px solid #00000038;
      position: relative;
      padding-top: -38px; }
  .profile .hover-profile:hover {
    cursor: pointer; }
  .profile .profile-detail {
    margin-top: 47px;
    flex-flow: column;
    max-width: 390px;
    justify-content: space-between; }
    @media (max-width: 640px) {
      .profile .profile-detail {
        text-align: center; } }
  .profile .profile-image {
    max-width: 100%; }
  .profile .round-button {
    width: 140px;
    margin-bottom: 60px;
    color: #abe6ed; }
    @media (max-width: 640px) {
      .profile .round-button {
        margin-bottom: 0px;
        width: 100%; } }
  .profile .create-button {
    background: #ffaa3c;
    margin-bottom: 0; }
    .profile .create-button:hover, .profile .create-button:active, .profile .create-button:focus {
      background: #ff9409; }
