.header .dropdown-wrapper .dropdown-toggle {
  font-family: "Permanent Marker", cursive; }

.header .dropdown-wrapper .teacher-dropdown-header-item, .header .dropdown-wrapper .dropdown-item {
  font-family: "Muli", sans-serif;
  font-weight: 800; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .header {
    line-height: 4; }
  .dropdown-item-setting {
    line-height: 24px; }
  .notification {
    line-height: 26px; } }

.header {
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  padding: 0 15px;
  min-height: 90px;
  justify-content: space-between; }
  @media (max-width: 640px) {
    .header {
      min-height: 60px; } }
  .header .teacher-profile-btn {
    padding: 0px 10px; }
  .header .header-title {
    color: #000;
    font-size: 26px;
    position: relative;
    flex: 1;
    text-align: center;
    margin-bottom: 0px; }
    @media (max-width: 640px) {
      .header .header-title {
        font-size: 18px; } }
    .header .header-title .button-group {
      font-size: inherit; }
  .header .header-profile-image {
    width: 30px; }
  .header .header-profile-btn svg {
    margin-top: -3px; }
    @media (max-width: 640px) {
      .header .header-profile-btn svg {
        width: 26px !important;
        height: 26px !important; } }
  .header .header-icons {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end; }
    @media (max-width: 640px) {
      .header .header-icons {
        display: none; } }
    .header .header-icons .classIcon img {
      height: 35px;
      width: 35px; }
  .header .header-icon {
    padding: 0 10px; }
  .header .header-logo-imgDiv {
    flex: 1;
    text-align: left; }
    .header .header-logo-imgDiv .header-logo-img {
      left: 15px;
      width: 110px;
      height: 47px;
      top: 50%; }
      @media (max-width: 640px) {
        .header .header-logo-imgDiv .header-logo-img {
          width: initial; } }
      .header .header-logo-imgDiv .header-logo-img.lg {
        display: inline-block; }
        @media (max-width: 640px) {
          .header .header-logo-imgDiv .header-logo-img.lg {
            display: none; } }
      .header .header-logo-imgDiv .header-logo-img.md {
        display: none; }
        @media (max-width: 640px) {
          .header .header-logo-imgDiv .header-logo-img.md {
            display: inline-block; } }
        @media (max-width: 420px) {
          .header .header-logo-imgDiv .header-logo-img.md {
            display: none; } }
      .header .header-logo-imgDiv .header-logo-img.sm {
        display: none; }
        @media (max-width: 420px) {
          .header .header-logo-imgDiv .header-logo-img.sm {
            display: inline-block; } }
  .header .dropdown-wrapper .dropdown-menu {
    background: #000;
    right: 10px;
    left: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 20px !important;
    border-radius: 0; }
    .header .dropdown-wrapper .dropdown-menu.show {
      display: block;
      transform: translate3d(-40px, 30px, 0px) !important; }
  .header .dropdown-wrapper .teacher-dropdown-header-item {
    font-size: 16px !important; }
  .header .dropdown-wrapper .dropdown-align,
  .header .dropdown-wrapper .dropdown-align-teacher {
    left: -45px !important;
    min-width: 8rem;
    text-align: center; }
  .header .dropdown-wrapper .dropdown-menu:after,
  .header .dropdown-wrapper .dropdown-menu:before {
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .header .dropdown-wrapper .dropdown-menu:before {
    border-bottom-color: #000;
    border-width: 10px;
    margin-left: -10px; }
  .header .dropdown-wrapper .dropdown-menu:after {
    border-bottom-color: #000;
    border-width: 10px;
    margin-left: -10px; }
  .header .dropdown-wrapper .dropdown-item:focus,
  .header .dropdown-wrapper .dropdown-item:hover {
    text-decoration: none;
    cursor: pointer; }
  .header .dropdown-wrapper .dropdown-item {
    color: #fff !important;
    font-weight: 400;
    font-size: 12px;
    white-space: normal;
    padding: 5px 10px;
    align-items: center;
    align-content: center;
    align-self: center;
    text-align: center;
    background: transparent;
    outline: none; }
  .header .dropdown-wrapper .dropdown-align-teacher:after,
  .header .dropdown-wrapper .dropdown-align-teacher:before {
    left: 75%; }
  .header .dropdown-wrapper .dropdown-item-setting {
    font-size: 16px; }
  .header .dropdown-wrapper .btn-secondary-bg-none {
    padding: 0;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    width: auto; }
  .header .dropdown-wrapper .dropdown-toggle {
    background: none !important;
    color: #000 !important;
    border: none !important;
    box-shadow: none !important;
    font-weight: 400; }
  .header .side-menu {
    display: none; }
    @media (max-width: 640px) {
      .header .side-menu {
        display: block; } }
