.center-content:before, .center-content:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.center-content:after {
  clear: both; }

.center-content {
  max-width: 100%;
  width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto; }
