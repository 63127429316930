.progress-dots {
  position: absolute;
  display: flex;
  bottom: 20px;
  right: 40px; }
  .progress-dots .dot {
    border: 1px solid #dfe7f0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%; }
    @media (max-width: 640px) {
      .progress-dots .dot {
        height: 10px;
        width: 10px; } }
    .progress-dots .dot:not(:last-child) {
      margin-right: 10px; }
  .progress-dots .current-dot {
    background-color: #f08700; }
  .progress-dots .past-dot {
    background-color: #044389; }
  .progress-dots .inactive-dot {
    background-color: #fff; }
