.app-pagination {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 640px) {
    .app-pagination {
      display: block; } }

.pagination-status {
  float: right;
  font-weight: 500; }
  @media (max-width: 640px) {
    .pagination-status {
      float: left;
      width: 100%; } }

@media (max-width: 640px) {
  .pagination {
    float: left;
    width: 100%; }
    .pagination .page-link {
      padding: 0.5rem 0.4rem; } }
