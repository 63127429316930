.logout-button {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  .logout-button .btn {
    border: none;
    border-radius: 0; }
