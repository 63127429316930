.input-form-group {
  position: relative; }

.select-list {
  list-style-type: none;
  position: absolute;
  background: #FEF6D4;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  padding: 0;
  max-height: 300px;
  overflow: auto; }
  .select-list li {
    cursor: pointer;
    font-size: 15px;
    padding: 4px 10px;
    color: #000; }
    .select-list li:hover {
      background-color: #000 !important;
      color: #fff !important; }

.input-with-loader {
  position: relative; }
  .input-with-loader .loading {
    height: 0px;
    right: 10px;
    left: unset;
    top: 4px; }
  .input-with-loader .loader-image {
    height: 30px;
    width: 30px;
    margin-top: 2%;
    margin: unset; }
