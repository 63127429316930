.hamburger-btn {
  background: none;
  border: none;
  outline: none !important;
  padding: 0 6px;
  line-height: 1;
  display: none; }
  .hamburger-btn .hamburger-image {
    width: 30px;
    height: 30px; }
    @media (max-width: 640px) {
      .hamburger-btn .hamburger-image {
        width: 21px; } }
    .hamburger-btn .hamburger-image:hover {
      cursor: pointer; }
  @media (max-width: 640px) {
    .hamburger-btn {
      display: block;
      flex: 1;
      text-align: right; } }
