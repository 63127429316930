@charset "UTF-8";
.input-form-group .show-password::before {
  display: inline-block;
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1; }

.input-form-group .input-wrapper {
  position: relative; }

.input-form-group .show-password {
  padding: 0;
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 5px;
  outline: none;
  font-size: 21px;
  color: #000;
  cursor: pointer; }
  .input-form-group .show-password::before {
    font-size: 20px;
    content: ""; }

.input-form-group .hide-password:before {
  content: ""; }

.input-form-group.password-group .form-control {
  padding-right: 30px; }
