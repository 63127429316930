.avatar-form-label {
  font-family: "Muli", sans-serif;
  font-weight: 600; }

.select-cnt select, .select-cnt select option {
  font-family: "Didact Gothic", sans-serif; }

.select-cnt .avatar-select-field {
  color: #000;
  border: 1px solid #000;
  outline: none !important; }

.select-cnt select {
  font-size: 20px;
  padding: 12px;
  color: #000;
  border: 1px solid #000;
  width: 100%;
  border-radius: 0.25rem; }
  .select-cnt select option {
    color: #000; }

.nested-group {
  margin-left: 50px; }

.avatar-form-label {
  font-size: 14pt;
  margin-bottom: 5px;
  font-weight: 700;
  color: #000; }

#Color\/Palette\/Blue-01 {
  fill: none; }

use#Circle-Background {
  fill: white;
  stroke: #000; }
