.back-btn {
  padding-right: 10px;
  float: left;
  left: 15px;
  flex: 1;
  text-align: left; }
  .back-btn .back-btn-tri {
    width: 15px;
    height: 31px;
    cursor: pointer; }
    @media (max-width: 640px) {
      .back-btn .back-btn-tri {
        width: 13px; } }
