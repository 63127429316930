.reset-password-modal .form-group input {
  font-family: "Muli", sans-serif;
  font-weight: 600; }

.reset-password-modal {
  justify-content: center; }
  .reset-password-modal .modal-content {
    width: 390px;
    border-radius: 10px; }
  .reset-password-modal .user-modal-body {
    padding: 10px 50px 60px; }
  .reset-password-modal .title-change-password {
    font-size: 22px; }
  .reset-password-modal .submit-btn {
    border-radius: 60px;
    padding: 18px;
    font-weight: bold;
    font-size: 16px !important;
    align-content: center;
    background: #000 !important; }
    .reset-password-modal .submit-btn:disabled {
      background: #aaaaaa !important; }
  .reset-password-modal .label {
    padding-top: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #000; }
  .reset-password-modal .form-group .sub-title {
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: center; }
  .reset-password-modal .form-group input {
    border: none; }
    .reset-password-modal .form-group input:-ms-input-placeholder {
      color: #000; }
    .reset-password-modal .form-group input:-moz-placeholder {
      color: #000; }
    .reset-password-modal .form-group input::-moz-placeholder {
      color: #000; }
    .reset-password-modal .form-group input::-webkit-input-placeholder {
      color: #000; }
  .reset-password-modal .form-control {
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #000 !important; }
    .reset-password-modal .form-control.is-invalid {
      background-image: none; }
    .reset-password-modal .form-control:focus {
      box-shadow: none; }
  .reset-password-modal .form-control::-ms-clear,
  .reset-password-modal .form-control::-ms-reveal {
    display: none; }
  .reset-password-modal .invalid-field {
    color: #ec7357;
    text-align: center; }
  .reset-password-modal .valid-field {
    color: #008000;
    text-align: center; }
