.graphic-novel-reading .story-upload-wrapper {
  margin: 30px 0 20px;
  max-height: none !important;
  min-height: 0 !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  margin-top: 0px !important; }

.graphic-novel-reading .blurb-heading {
  margin: 0px 0 30px; }

.graphic-novel-reading .upload-cover-content {
  font-weight: bold;
  max-width: 100%;
  width: 1000px;
  padding: 0 130px;
  margin: 30px auto;
  text-align: center;
  display: flex;
  flex-direction: column; }
  @media (max-width: 1024px) {
    .graphic-novel-reading .upload-cover-content {
      padding: 0 80px; } }
  @media (max-width: 640px) {
    .graphic-novel-reading .upload-cover-content {
      padding: 0 0; } }

.graphic-novel-reading .novel-title {
  padding: 0 10px; }

.graphic-novel-reading .upload-cover-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%; }

.graphic-novel-reading .carousel .slide {
  background: none; }

.graphic-novel-reading .carousel img {
  pointer-events: auto; }

.graphic-novel-reading .carasol-wrapper {
  position: relative; }
  @media (max-width: 640px) {
    .graphic-novel-reading .carasol-wrapper {
      padding-bottom: 30px; } }

.graphic-novel-reading .previous-slide {
  background: url("../../assets/images/back-arrow.svg") no-repeat center #dfe7f0 !important;
  background-size: 14px !important;
  border: none !important;
  color: #044389 !important;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  font-size: 44px;
  font-weight: normal;
  padding: 0;
  position: absolute;
  top: 50%;
  margin-top: -45px;
  left: -120px;
  line-height: 1; }
  @media (max-width: 1024px) {
    .graphic-novel-reading .previous-slide {
      width: 60px;
      height: 60px;
      margin-top: -30px;
      left: -70px;
      background-position-x: 21px !important; } }
  @media (max-width: 640px) {
    .graphic-novel-reading .previous-slide {
      width: 35px;
      height: 35px;
      margin-top: -18px;
      left: 20px;
      background-position-x: 12px !important;
      background-size: 8px !important;
      bottom: -17px;
      top: auto; } }

.graphic-novel-reading .next-slide {
  background: url("../../assets/images/back-arrow.svg") no-repeat center #dfe7f0 !important;
  background-size: 14px !important;
  transform: rotate(180deg);
  border: none !important;
  color: #044389 !important;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  font-size: 44px;
  font-weight: normal;
  padding: 0;
  position: absolute;
  top: 50%;
  margin-top: -45px;
  line-height: 1;
  right: -120px; }
  @media (max-width: 1024px) {
    .graphic-novel-reading .next-slide {
      width: 60px;
      height: 60px;
      margin-top: -30px;
      right: -70px;
      background-position-x: 21px !important; } }
  @media (max-width: 640px) {
    .graphic-novel-reading .next-slide {
      width: 35px;
      height: 35px;
      margin-top: -18px;
      right: 20px;
      background-position-x: 12px !important;
      background-size: 8px !important;
      bottom: -17px;
      top: auto; } }

.graphic-novel-reading .cropped-img-box {
  max-height: 405px;
  width: auto !important;
  max-width: 100%; }
  @media (max-width: 640px) {
    .graphic-novel-reading .cropped-img-box {
      max-height: 280px; } }

.graphic-novel-reading .story-reading {
  max-width: initial;
  width: 100%; }
