.login .invalid-feedback {
  font-family: "Muli", sans-serif;
  font-weight: 600; }

.login .error-message, .login .form-group .form-control {
  font-family: "Didact Gothic", sans-serif; }

.login {
  min-height: 100vh;
  background: #d5f2f6;
  position: relative; }
  .login:before {
    content: "";
    min-height: inherit;
    font-size: 0; }
  .login:after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 50%;
    background: #abe6ed;
    content: "";
    transition: 0.3s all;
    border-left: 1px solid #000; }
    @media (max-width: 640px) {
      .login:after {
        opacity: 0; } }
    @media (max-width: 1024px) {
      .login:after {
        opacity: 0;
        background: #fff; } }
  .login .login-form {
    max-width: 320px;
    width: 95%;
    padding: 25px 25px 48px 25px;
    background: #fdedb5;
    z-index: 1;
    border: 1px solid #000; }
    @media (max-width: 640px) {
      .login .login-form {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; } }
  .login .suffix-form {
    max-width: 450px;
    width: 95%;
    padding: 25px 25px 48px 25px;
    background: #fdedb5;
    z-index: 1;
    border: 1px solid #000; }
    @media (max-width: 640px) {
      .login .suffix-form {
        max-width: 100%;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; } }
  .login .suffix-back-button {
    color: #000;
    cursor: pointer;
    font-size: 30px; }
  .login .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .login h1 {
    margin-bottom: 30px;
    color: #044389; }
  .login .btn-secondary {
    margin-top: 20px; }
  .login .error-message {
    font-size: 10px;
    color: #ec7357; }
  .login .invalid-feedback {
    color: #f77a1c !important;
    text-align: right;
    font-size: 12px; }
  .login .form-group label {
    color: #000; }
  .login .form-group .form-control {
    color: #000;
    border: none;
    border-bottom: 2px solid #000;
    border-radius: 0px;
    background-color: #fef6d4;
    font-size: 14px; }
    .login .form-group .form-control:focus {
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
      color: #000; }
    .login .form-group .form-control.is-invalid {
      border-width: 2px;
      border-color: #ec7357;
      border-bottom: 2px solid #f77a1c !important;
      box-shadow: 0 0 0 0.1rem #f77a1c !important; }
  .login .form-group .form-control::-ms-clear,
  .login .form-group .form-control::-ms-reveal {
    display: none; }
  .login .login-logo-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px; }
    .login .login-logo-wrapper .login-logo {
      width: 130px;
      height: intrinsic; }
  .login .loginBtn {
    color: #fdedb5 !important; }

@media (max-width: 640px) {
  .swal2-popup .swal2-content {
    font-size: 1rem; } }

.forgotPassCls {
  font-size: 12px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  position: relative;
  top: -10px;
  justify-content: flex-end; }
  .forgotPassCls:hover {
    text-decoration: none;
    cursor: pointer; }
