.page-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.page-center2 {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 640px) {
    .page-center2 {
      align-items: flex-start; } }
