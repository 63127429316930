.libray-btn {
  background: none;
  border: none;
  outline: none !important;
  padding: 0 6px;
  line-height: 1; }
  .libray-btn .library-image {
    width: 30px;
    height: 30px; }
    @media (max-width: 640px) {
      .libray-btn .library-image {
        width: 21px; } }
    .libray-btn .library-image:hover {
      cursor: pointer; }
