@charset "UTF-8";
.comments-editor:before, .comments-editor .action-buttons:before, .comments-editor:after, .comments-editor .action-buttons:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.comments-editor:after, .comments-editor .action-buttons:after {
  clear: both; }

.comments-editor .emojis-item:before {
  display: inline-block;
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1; }

.comments-editor .list-item {
  font-family: "Quicksand", sans-serif;
  font-weight: 700; }

.comments-editor {
  max-width: 90%;
  width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 50px auto;
  text-align: center;
  position: relative;
  margin-bottom: 35px !important;
  margin-top: 18px !important;
  padding-bottom: 22px; }
  .comments-editor.personalForm {
    background: #fff;
    border: 1px solid #000; }
  .comments-editor .close-btn {
    background: url("../../assets/images/cross.svg") no-repeat center transparent !important;
    background-size: contain !important;
    border: none;
    position: absolute;
    right: 25px;
    top: 15px;
    width: 18px;
    z-index: 1;
    border-radius: 0;
    box-shadow: none !important; }
  .comments-editor .list {
    overflow: hidden;
    margin: 15px 0 30px;
    color: #000; }
    .comments-editor .list.selected .list-item {
      opacity: 0.5; }
  .comments-editor .list-item {
    border: 1px solid #000;
    border-top: none;
    padding: 13px 20px;
    transition: 0.3s all;
    cursor: pointer;
    font-size: 16px;
    background: #fff;
    text-align: left; }
    @media (max-width: 640px) {
      .comments-editor .list-item {
        font-size: 12px; } }
    .comments-editor .list-item:first-child {
      border-top: 1px solid #000; }
    .comments-editor .list-item.selected, .comments-editor .list-item:hover {
      background: #fff;
      border-radius: 4px;
      opacity: 1 !important; }
    .comments-editor .list-item.selected {
      border-bottom: 1px solid #000;
      background: #b0e4eb; }
  .comments-editor .submit-button {
    font-size: 19px;
    padding: 12px 30px;
    float: right;
    background-color: #000; }
    .comments-editor .submit-button:disabled {
      background: #3d3d3e; }
    @media (max-width: 640px) {
      .comments-editor .submit-button {
        width: 100%; } }
  .comments-editor .emojis-list {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 20px 0;
    color: #000; }
    .comments-editor .emojis-list.selected .emojis-item {
      opacity: 0.5; }
  .comments-editor .emojis-item {
    border: 1px solid #000;
    width: 60px;
    background: #fff;
    text-align: center;
    padding: 17px 5px 17px;
    font-size: 0;
    border-radius: 5px;
    margin: 0 3px;
    transition: 0.3s all;
    cursor: pointer; }
    .comments-editor .emojis-item:first-child {
      margin-left: 0; }
    .comments-editor .emojis-item:last-child {
      margin-right: 0; }
    .comments-editor .emojis-item:before {
      font-size: 26px;
      font-weight: bold; }
    .comments-editor .emojis-item.selected, .comments-editor .emojis-item:hover {
      background: #fff !important;
      border: 1px solid #000;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
      opacity: 1 !important; }
  .comments-editor .emoji-laugh:before {
    content: ""; }
  .comments-editor .emoji-love:before {
    content: ""; }
  .comments-editor .emoji-cry:before {
    content: ""; }
  .comments-editor .emoji-shock:before {
    content: ""; }
  .comments-editor .emoji-happy:before {
    content: ""; }
  .comments-editor .submiteActionWrap {
    display: flex;
    justify-content: center; }
