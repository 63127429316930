.loading {
  position: absolute;
  left: 0;
  text-align: center;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  background: rgba(255, 255, 255, 0.8);
  z-index: 100; }
  .loading .loader-image {
    margin: auto;
    height: 60px;
    width: 60px; }
