.story-reading {
  background: #fff;
  padding: 20px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: 1px solid #dfe7f0;
  color: #044389;
  margin: 0;
  display: flex;
  flex: 1;
  max-width: 1110px;
  flex-direction: column; }
  .story-reading .body {
    margin: 15px 0 0 0;
    white-space: pre-line;
    flex: 1;
    display: flex;
    overflow-x: hidden;
    max-height: 445px; }
    @media (max-width: 1024px) {
      .story-reading .body {
        font-size: 14px; } }
  .story-reading .blurb-title {
    font-size: 20px;
    margin-top: 20px; }
    @media (max-width: 640px) {
      .story-reading .blurb-title {
        font-size: 14px; } }
  .story-reading .story-detail {
    flex: 1; }
  .story-reading .profile-name {
    text-align: center;
    color: #ffaa3c;
    padding-top: 5px; }
    .story-reading .profile-name:hover {
      cursor: pointer; }
  .story-reading .story-sub-title {
    font-size: 14px; }
  .story-reading .orange-btn {
    padding: 0;
    color: #ffaa3c !important;
    text-decoration: underline !important;
    font-weight: bold;
    font-size: inherit;
    border: 0;
    border-radius: 0;
    background: none !important;
    box-shadow: none !important; }
  @media (max-width: 1024px) {
    .story-reading h3 {
      font-size: 18px;
      letter-spacing: 0.5; } }
  .story-reading .story-heading {
    margin-bottom: 15px; }
    @media (max-width: 640px) {
      .story-reading .story-heading {
        flex-direction: column; } }
  .story-reading .story-title-genre {
    width: 24px;
    vertical-align: top;
    display: inline-block;
    margin: 0 6px; }
    @media (max-width: 640px) {
      .story-reading .story-title-genre {
        margin: 0 6px 10px; } }
  .story-reading .remaning-genres {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(223, 231, 240, 0.5);
    border: 1px solid #dfe7f0;
    display: inline-block;
    margin: 4px 0 0 6px;
    font-size: 14px;
    text-align: center;
    vertical-align: top;
    padding-top: 3px; }
    @media (max-width: 640px) {
      .story-reading .remaning-genres {
        display: none; } }
  .story-reading .story-title-genres-list {
    text-align: right;
    float: right;
    position: relative; }
    @media (max-width: 640px) {
      .story-reading .story-title-genres-list {
        float: none; } }
  .story-reading .genres-displaying {
    list-style: none;
    margin: 0;
    padding: 0 0 0 10px; }
    @media (max-width: 640px) {
      .story-reading .genres-displaying {
        padding: 0;
        display: inline-block; } }
  .story-reading .genres-hidden {
    padding: 5px;
    margin: 0;
    display: none;
    list-style: none;
    position: absolute;
    z-index: 1;
    top: 55px;
    right: -5px;
    border: 1px solid #dfe7f0;
    background: #f2f5f9;
    border-radius: 4px;
    box-shadow: 3px 3px 12px rgba(4, 67, 137, 0.12); }
    @media (max-width: 640px) {
      .story-reading .genres-hidden {
        display: inline;
        background: none;
        border: none;
        position: static;
        padding: 0;
        box-shadow: none; } }
    .story-reading .genres-hidden:before {
      content: "";
      display: inline-block;
      position: absolute;
      top: -14px;
      right: 9px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #f2f5f9; }
      @media (max-width: 640px) {
        .story-reading .genres-hidden:before {
          display: none; } }
  .story-reading .up-arrow {
    background: #f2f5f9;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 46px;
    right: 10px;
    transform: rotate(-45deg);
    box-shadow: 3px 3px 12px rgba(4, 67, 137, 0.12);
    outline: 1px solid #dfe7f0;
    display: none; }
    @media (max-width: 640px) {
      .story-reading .up-arrow {
        display: none !important; } }
  .story-reading .display-all-genres .genres-hidden,
  .story-reading .display-all-genres .up-arrow {
    display: block; }
