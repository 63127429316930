.header-profile-btn .header-profile-image {
  max-width: 100%;
  width: 30px; }
  @media (max-width: 640px) {
    .header-profile-btn .header-profile-image {
      width: 22px; } }
  .header-profile-btn .header-profile-image:hover {
    cursor: pointer; }

.header-profile-btn.hover-avatar:hover {
  cursor: pointer; }

.header-profile-btn.no-hover-avatar:hover {
  cursor: default; }
