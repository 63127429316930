@charset "UTF-8";
.story-statistics .stat-emoji:before {
  display: inline-block;
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1; }

.story h4 {
  font-family: "Permanent Marker", cursive; }

.story .story-draft, .story button {
  font-family: "Muli", sans-serif;
  font-weight: 800; }

.story .story-description {
  font-family: "Didact Gothic", sans-serif; }

.story {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  margin: 0px 20px 30px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #000;
  min-height: 210px;
  position: relative;
  height: 210px; }
  .story.storyWinner {
    height: 255px;
    max-width: initial;
    margin: 0px 10px 10px 0px; }
    @media (max-width: 1920px) {
      .story.storyWinner {
        width: 32.5%; } }
    @media (max-width: 1450px) {
      .story.storyWinner {
        width: 48.5%; } }
    @media (max-width: 640px) {
      .story.storyWinner {
        width: 100%; } }
  @media (max-width: 1024px) {
    .story {
      width: 264px;
      min-width: 264px;
      margin: 0px 10px 30px; } }
  @media (max-width: 640px) {
    .story {
      width: 100%;
      min-width: 250px;
      max-width: 100%;
      margin: 0px 10px 30px; } }
  .story h4 {
    color: #000; }
  .story .body {
    white-space: pre-line; }
  .story .column-image {
    float: left;
    width: 120px;
    padding-right: 2px;
    overflow: hidden; }
    .story .column-image:empty {
      display: none; }
  .story .column-default {
    float: left;
    width: 130px !important;
    height: 150px; }
  .story .story-body-wrapper {
    padding: 5px 10px 0; }
  .story .story-body {
    color: #000;
    font-weight: bold;
    font-size: 10px;
    padding-top: 5px; }
  .story .story-draft {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .story .story-image-wrapper {
    width: 100%;
    text-align: center; }
  .story .story-image {
    min-height: 163px;
    width: 100%; }
  .story .story-image-winner {
    min-height: 186px;
    width: 100%; }
  .story .story-description {
    font-size: 14px;
    flex: 1 0 auto;
    color: #000;
    margin-top: 10px;
    font-weight: 100; }
  .story .story-row-flex {
    display: block;
    height: 163px; }
    .story .story-row-flex.winnersStoryFlex {
      height: 205px; }
  .story:hover .delete-cross {
    visibility: visible; }
  .story button {
    background: #000 !important;
    border: 2px solid #000 !important; }

.story-button {
  background: #000 !important;
  color: #fff !important; }

.story-statistics {
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  white-space: nowrap;
  position: absolute;
  bottom: 50px;
  right: -15px; }
  @media (max-width: 640px) {
    .story-statistics {
      justify-content: center; } }
  .story-statistics .story-stat {
    font-size: 15px;
    color: #000; }
  .story-statistics .v-middle {
    vertical-align: middle; }
  .story-statistics .story-views .view-icon {
    width: 17px;
    margin: 0px 8px; }
  .story-statistics .story-fb-count {
    margin-right: 25px; }
    .story-statistics .story-fb-count .feedback-icon {
      width: 15px;
      margin: 0px 8px; }
  .story-statistics .story-reactions .reaction-count {
    vertical-align: middle; }
  .story-statistics .stat-emoji {
    margin-right: 5px;
    display: inline-block; }
    .story-statistics .stat-emoji:before {
      content: "";
      vertical-align: middle; }
  .story-statistics .emoji-laugh:before {
    content: ""; }
  .story-statistics .emoji-love:before {
    content: ""; }
  .story-statistics .emoji-cry:before {
    content: ""; }
  .story-statistics .emoji-shock:before {
    content: ""; }
  .story-statistics .emoji-happy:before {
    content: ""; }
