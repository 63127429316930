.story-robot {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  font-size: 16px;
  color: #044389;
  font-weight: bold; }
  .story-robot.screen-center {
    height: 100vh;
    margin-top: -45px;
    min-height: 320px; }
  .story-robot p {
    margin-top: 10px;
    padding: 0 20px; }
  .story-robot .robot-wrap {
    position: relative;
    width: 100%; }
    .story-robot .robot-wrap:before {
      position: absolute;
      top: 130px;
      content: "";
      background: #044389;
      height: 1px;
      width: 100%;
      left: 0;
      right: 0; }
  .story-robot .robot-image {
    max-width: 110px; }
