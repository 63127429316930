@charset "UTF-8";
.notification .laugh:before, .notification .love:before, .notification .shock:before, .notification .cry:before, .notification .happy:before {
  display: inline-block;
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1; }

.notification {
  z-index: 1;
  position: absolute;
  text-align: left; }
  .notification .notification-overlay {
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .notification .notification-wrapper {
    width: 520px;
    max-width: 90%;
    border-left: 2px solid #000;
    position: fixed;
    overflow: auto;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    padding: 60px 30px 30px; }
    @media (max-width: 640px) {
      .notification .notification-wrapper {
        padding: 60px 15px 30px; } }
  .notification .notification-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 20px; }
    @media (max-width: 640px) {
      .notification .notification-list {
        font-size: 14px; } }
  .notification h2 {
    color: #000;
    margin-bottom: 30px; }
  .notification .notification-item {
    display: flex;
    padding: 25px 0; }
    .notification .notification-item.pointer:hover {
      background: #000;
      color: #fff;
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
      transition: 0.3s background; }
      @media (max-width: 640px) {
        .notification .notification-item.pointer:hover {
          margin-left: -15px;
          margin-right: -15px;
          padding-left: 15px;
          padding-right: 15px; } }
      .notification .notification-item.pointer:hover b {
        transition: 0.3s color;
        color: #fff; }
      .notification .notification-item.pointer:hover .love {
        color: black; }
  .notification .active {
    border-top: 2px solid #044389;
    border-bottom: 2px solid #044389;
    background: #bacbde;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px; }
    @media (max-width: 640px) {
      .notification .active {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px; } }
    .notification .active b {
      color: #044389; }
  .notification .active + .active {
    border-top: none; }
  .notification .notification-image-wrap {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    background: no-repeat center transparent; }
    @media (max-width: 640px) {
      .notification .notification-image-wrap {
        width: 30px;
        min-width: 30px;
        height: 30px; } }
  .notification .teacherFeedback {
    background-image: url("../../assets/images/feedback.svg"); }
  .notification .storyPublished {
    background-image: url("../../assets/images/published.svg"); }
  .notification .laugh:before, .notification .love:before, .notification .shock:before, .notification .cry:before, .notification .happy:before {
    font-size: 60px;
    font-weight: bold;
    content: "";
    background: #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px; }
    @media (max-width: 640px) {
      .notification .laugh:before, .notification .love:before, .notification .shock:before, .notification .cry:before, .notification .happy:before {
        width: 30px;
        height: 30px;
        font-size: 30px; } }
  .notification .love:before {
    content: ""; }
  .notification .shock:before {
    content: ""; }
  .notification .cry:before {
    content: ""; }
  .notification .happy:before {
    content: ""; }
  .notification .none {
    background-image: url("../../assets/images/feedback.svg"); }
  .notification .classmateStoryPublished {
    background-image: url("../../assets/images/class published.svg"); }
  .notification .peopleReadingWork {
    background-image: url("../../assets/images/reading.svg"); }
  .notification .notification-content {
    flex: 1;
    max-width: calc(100% - 80px); }
    @media (max-width: 640px) {
      .notification .notification-content {
        max-width: calc(100% - 50px); } }
  .notification .close-btn {
    background: url("../../assets/images/cross.svg") no-repeat center transparent !important;
    background-size: contain !important;
    border: none;
    position: absolute;
    right: 20px;
    top: 10px;
    width: 30px;
    z-index: 1;
    border-radius: 0;
    box-shadow: none !important; }
  .notification .header-profile-image {
    width: 60px; }
