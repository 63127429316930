.modal-popup .btn-primary {
  font-family: "Muli", sans-serif;
  font-weight: 800; }

.modal-popup .modelDescription {
  font-family: "Muli", sans-serif;
  font-weight: 600; }

.modal-backdrop.show {
  background: #fff;
  color: #000 !important; }

.storySubmitted {
  background: #cfecbf; }

.modal-popup .modal-content {
  border: 2px solid #000;
  border-radius: 0; }

.modal-popup .modal-header {
  border: none; }

.modal-popup .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .modal-popup .modal-body .form-group {
    max-width: 100% !important; }

.modal-popup .modal-footer {
  border: none;
  justify-content: center;
  padding: 10px 0px 40px 0px; }

.modal-popup .btn-primary {
  color: #fff;
  background-color: #000 !important;
  border-color: #000 !important;
  font-size: 16px;
  padding: 12px 28px;
  border-radius: 35px;
  box-shadow: none !important; }
  @media (max-width: 640px) {
    .modal-popup .btn-primary {
      padding: 12px 20px; } }
  .modal-popup .btn-primary.outlined {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #000 !important;
    box-shadow: none !important;
    border: 3px solid #000; }

.modal-popup h3 {
  text-align: center;
  padding: 20px 0px 30px 0px;
  color: #000;
  width: 100%; }

.modal-popup .close {
  font-size: 0;
  background: url("../../assets/images/cross.svg") no-repeat left bottom transparent;
  background-size: 20px;
  opacity: 1;
  outline: none; }
  .modal-popup .close:hover {
    opacity: 1 !important; }

.modal-popup p {
  text-align: center;
  color: #000 !important;
  line-height: 35px;
  margin-bottom: 0px; }

.modal-popup .modelDescription {
  width: 100%; }

.modal-popup .vertically {
  flex-flow: column; }
  .modal-popup .vertically .btn-primary {
    margin: 0;
    border-radius: 0; }
  .modal-popup .vertically .white-button {
    margin: 30px 0 0;
    border-radius: 5px;
    border: 1px solid #044389 !important;
    max-width: 180px;
    min-width: 130px;
    font-weight: normal; }

.modal-footer > :not(:last-child) {
  margin-right: 1.25rem; }

.closeSaveWorkspace {
  background: #e7f6df; }
