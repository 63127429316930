.notification-btn {
  background: none;
  border: none;
  outline: none !important;
  padding: 0 6px; }
  .notification-btn .notification-image {
    width: 24px;
    height: 31px; }
    @media (max-width: 640px) {
      .notification-btn .notification-image {
        width: 18px;
        height: 20px; } }
    .notification-btn .notification-image:hover {
      cursor: pointer; }
