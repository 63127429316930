@charset "UTF-8";
.feedback .feedback-action:before, .feedback .feedback-action:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.feedback .feedback-action:after {
  clear: both; }

.feedback .comment-img:before, .feedback .publish-img:before {
  display: inline-block;
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1; }

.feedback .comment-img-personal {
  font-family: "Permanent Marker", cursive; }

.feedback .feedback-group p.title {
  font-family: "Muli", sans-serif;
  font-weight: 800; }

.feedback {
  padding: 20px 0;
  color: #000;
  line-height: 1.3;
  background: #b0e4eb;
  border: 1px solid #000;
  border-top: none; }
  .feedback .feedback-center {
    margin: 0 auto; }
    .feedback .feedback-center .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .feedback .comment-img-personal {
    float: right;
    font-size: 14px;
    align-self: flex-start; }
    .feedback .comment-img-personal:before {
      font-size: 24px; }
  .feedback .comment-img {
    float: right; }
    .feedback .comment-img:before {
      font-size: 24px;
      content: ""; }
  .feedback .publish-img {
    float: right; }
    .feedback .publish-img:before {
      content: "";
      font-size: 24px; }
  .feedback .feedback-row {
    display: flex;
    align-items: center; }
  .feedback .feedback-group {
    margin-left: 10px; }
    .feedback .feedback-group p.title {
      font-size: 12px;
      margin: 0; }
  .feedback .body p {
    margin: 5px 0px 0px 0px;
    font-size: 16px; }
    @media (max-width: 640px) {
      .feedback .body p {
        font-size: 14px; } }
  .feedback .feedback-action {
    float: right; }
    @media (max-width: 640px) {
      .feedback .feedback-action {
        width: 100%;
        float: none;
        margin-top: 10px; } }
  .feedback .accept-feedback {
    padding: 10px 20px !important;
    background: #ffaa3c !important; }
    @media (max-width: 640px) {
      .feedback .accept-feedback {
        width: 49% !important;
        float: right;
        margin-bottom: 0 !important; } }
  .feedback .decline-feedback {
    padding: 10px 20px !important; }
    @media (max-width: 640px) {
      .feedback .decline-feedback {
        width: 49% !important;
        float: left;
        margin-bottom: 0 !important; } }
