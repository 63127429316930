.topic-cnt .title {
  margin-bottom: 20px;
  color: #fff; }

.topic-cnt .body {
  font-weight: lighter;
  font-size: 18px;
  margin-bottom: 10px;
  white-space: pre-line;
  color: #fff; }
  @media (max-width: 640px) {
    .topic-cnt .body {
      font-size: 12px; } }
