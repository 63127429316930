.genre-icon-cnt {
  display: flex;
  /* establish flex container */
  flex-direction: column;
  /* make main axis vertical */
  justify-content: center;
  /* center items vertically, in this case */
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  bottom: 0; }
  .genre-icon-cnt .genreWrap {
    width: 100%; }
  .genre-icon-cnt .genre-icon-flex-row {
    display: flex;
    flex: 1; }
    .genre-icon-cnt .genre-icon-flex-row .genre-icon-flex-col {
      padding: 3px; }
  .genre-icon-cnt .iconBox {
    border-right: 1px solid black;
    border-bottom: 1px solid black; }
  .genre-icon-cnt .iconRow {
    border-left: 1px solid black;
    padding-bottom: 68px; }
