@charset "UTF-8";
.review-comments-overlay:before, .review-comments-overlay:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.review-comments-overlay:after {
  clear: both; }

.review-comments-overlay .emojis-item:before {
  display: inline-block;
  font-family: "icomoon", sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1; }

.review-comments-overlay .review-comments-item {
  font-family: "Muli", sans-serif;
  font-weight: 800; }

.review-comments-overlay .review-comments {
  max-width: 90%;
  width: 1200px;
  padding: 50px 24px;
  margin: 0 auto; }

.review-comments-overlay .review-comments-list {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin: 15px 0 35px; }
  .review-comments-overlay .review-comments-list.selected .review-comments-item {
    opacity: 0.5; }

.review-comments-overlay .review-comments-item {
  padding: 14px 14px;
  color: #000;
  transition: 0.3s all;
  display: flex;
  border-bottom: 1px solid #000;
  background: rgba(255, 255, 255, 0.4);
  flex-flow: row; }
  @media (max-width: 640px) {
    .review-comments-overlay .review-comments-item {
      padding: 11px 14px; } }
  .review-comments-overlay .review-comments-item:last-child {
    border-bottom: 0; }
  .review-comments-overlay .review-comments-item.selected {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    opacity: 1 !important; }

.review-comments-overlay .comments-count {
  text-align: center;
  font-weight: bold;
  font-size: 18px; }

.review-comments-overlay .comments-name {
  flex: 1;
  font-size: 16px; }
  @media (max-width: 640px) {
    .review-comments-overlay .comments-name {
      font-size: 12px; } }

.review-comments-overlay .emojis-list {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 20px 0;
  color: #000; }
  .review-comments-overlay .emojis-list.selected .emojis-item {
    opacity: 0.5; }

.review-comments-overlay .emojis-item {
  width: 60px;
  text-align: center;
  background: rgba(255, 255, 255, 0.4);
  margin: 0 3px;
  font-size: 0;
  padding: 8px 5px 4px;
  transition: 0.3s all;
  font-weight: bold;
  border: 1px solid #000; }
  .review-comments-overlay .emojis-item:first-child {
    margin-left: 0; }
  .review-comments-overlay .emojis-item:last-child {
    margin-right: 0; }
  .review-comments-overlay .emojis-item:before {
    display: block;
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: bold; }
  .review-comments-overlay .emojis-item.selected {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    opacity: 1 !important; }

.review-comments-overlay .emoji-count {
  font-size: 20px;
  line-height: 1; }
  @media (max-width: 640px) {
    .review-comments-overlay .emoji-count {
      font-size: 12px; } }

.review-comments-overlay .emoji-laugh:before {
  content: ""; }

.review-comments-overlay .emoji-love:before {
  content: ""; }

.review-comments-overlay .emoji-cry:before {
  content: ""; }

.review-comments-overlay .emoji-shock:before {
  content: ""; }

.review-comments-overlay .emoji-happy:before {
  content: ""; }
