.side-nav .user-name {
  font-family: "Permanent Marker", cursive; }

.side-nav .side-nav-item div, .side-nav .side-nav-sub-item div {
  font-family: "Muli", sans-serif;
  font-weight: 800; }

.side-nav {
  z-index: 1;
  position: relative;
  text-align: left;
  display: none; }
  .side-nav .side-nav-overlay {
    background: rgba(255, 255, 255, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .side-nav .side-nav-wrapper {
    width: 520px;
    max-width: 90%;
    border-left: 1px solid #000;
    position: fixed;
    overflow: auto;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    display: flex;
    flex: 1;
    flex-direction: column; }
  .side-nav .side-nav-profile-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 50px; }
  .side-nav .user-name {
    font-size: 24px;
    margin: 0 15px; }
  .side-nav .side-nav-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px; }
  .side-nav .side-nav-item {
    display: flex;
    align-items: center;
    padding: 25px;
    border-top: 1px solid #000; }
    .side-nav .side-nav-item img {
      width: 30px; }
    .side-nav .side-nav-item div {
      padding: 0 20px;
      font-size: 20px; }
  .side-nav .side-nav-sub-item {
    padding: 20px;
    padding: 10px 0 10px 25px; }
    .side-nav .side-nav-sub-item div {
      padding: 0 10px;
      font-size: 16px; }
  .side-nav .side-nav-content {
    flex: 1;
    max-width: calc(100% - 80px); }
    @media (max-width: 640px) {
      .side-nav .side-nav-content {
        max-width: calc(100% - 50px); } }
  @media (max-width: 640px) {
    .side-nav {
      display: block; } }
