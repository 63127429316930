.icon-card {
  height: 250px;
  padding: 20px 8px 20px 10px;
  border-radius: 12px;
  transition: all 0.2s ease-in-out; }
  .icon-card:hover {
    cursor: pointer;
    transform: scale(0.95); }
  .icon-card .icon-card-img-cnt {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 640px) {
      .icon-card .icon-card-img-cnt {
        height: 80%; } }
    .icon-card .icon-card-img-cnt .icon-card-image {
      width: 100px;
      height: 100px; }
      @media (max-width: 1024px) {
        .icon-card .icon-card-img-cnt .icon-card-image {
          width: 80px;
          height: 80px; } }
      @media (max-width: 640px) {
        .icon-card .icon-card-img-cnt .icon-card-image {
          width: 100px;
          height: 100px; } }
  .icon-card .icon-card-text-cnt {
    height: 10%; }
    .icon-card .icon-card-text-cnt .icon-card-text {
      font-size: 10pt;
      font-weight: bold;
      text-align: center; }
      @media (max-width: 1024px) {
        .icon-card .icon-card-text-cnt .icon-card-text {
          font-weight: bolder;
          font-size: 8pt; } }
      @media (max-width: 640px) {
        .icon-card .icon-card-text-cnt .icon-card-text {
          font-size: 8pt; } }
  @media (max-width: 1024px) {
    .icon-card {
      height: 180px; } }
  @media (max-width: 640px) {
    .icon-card {
      padding: 0px; } }
